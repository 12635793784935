<template>
  <b-card class="school_preview" header-class="text-right" footer-class="text-right">
    <template v-slot:header>
      <slot name="controls">
        <span />
      </slot>
    </template>
    <template v-slot:footer>
      <slot name="controls">
        <span />
      </slot>
    </template>

    <b-table stacked :items="school" :busy="busy" :fields="fields" show-empty>
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-2" />
          <span>Loading...</span>
        </div>
      </template>
      <template v-slot:empty>
        <h4>Specified school not found.</h4>
      </template>
      <template v-slot:cell(coverimage)="cell">
        <div class="cover row">
          <div class="img_div">
            <b-img :src="cell.value" />
          </div>
        </div>
      </template>
      <template v-slot:cell(school_admins)="cell">
        <ul class="list-unstyled">
          <li v-for="(admin, i) in cell.item.school_admins" :key="i">
            <router-link :to="{name:'user', params:{id: admin.id}}">{{ admin.display_name }}</router-link>
          </li>
        </ul>
      </template>
    </b-table>

  </b-card>
</template>

<script>

export default {
  name: 'SchoolPreview',
  props: {
    school: { type: [Array, Function], required: true },
    busy: { type: Boolean, default: false },
  },

  data() {
    return {
      fields: [
        { key: 'name' },
        { key: 'email' },
        { key: 'telephone' },
        { key: 'address' },
        { key: 'coverimage' },
        { key: 'school_admins', label: 'Administrators' },
      ],
    };
  },

};
</script>

<style lang="stylus" scoped>

>>> .b-table-stacked
  td:first-child
    border 0
    padding-top 0
  ul
    margin-bottom 0
  .img_div
    position relative
    width 5rem
    padding 0 0.3rem 0.5rem 0.3rem
    img
      width 100%

</style>
